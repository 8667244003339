.background-container {
    background-image: url('/public/assets/background.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh; /* Ensures it works on small screens */
    display: flex;
    flex-direction: column; /* Ensures vertical stacking of header and content */
    align-items: center;
    justify-content: space-between; /* Space between header and content */
    text-align: center;
    color: white;
    padding: 2rem; /* Adds spacing around the content */
  }
  
  .header {
    width: 78%;
    display: flex;
    justify-content: flex-start; /* Aligns header content to the left */
    padding: 1rem;
  }
  
  .brand {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .content {
    background: rgba(0, 0, 0, 0.2); /* Slightly darker semi-transparent background */
    padding: 2rem;
    border-radius: 1rem;
    width: 90%; /* Responsive width */
    max-width: 600px; /* Ensures it doesn't stretch too much */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .title {
    font-size: 1.5rem; /* Adjusted for readability on all devices */
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffcc00;

  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  .buttons {
    display: flex;
    gap: 1rem; /* Space between buttons */
    justify-content: center;
  }
  
  .button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #ffcc00; /* Yellow color */
    color: black;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
  }
  
  .button:hover {
    background-color: #ffaa00;
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  